
export default {
  props: {
    blok: {
      type: Object,
      default: () => ({}),
    },
    to: {
      type: String,
      default: "",
    },
    url: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
    // TODO: Hook textColor and color properties
    textColor: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "",
    },
    variant: {
      type: String,
      default: "primary",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    // size: {
    //   type: String,
    //   default: ''
    // },
  },
  computed: {
    isLink() {
      return (
        Boolean(this.url) ||
        Boolean(this.blok.url?.cached_url) ||
        Boolean(this.blok.link?.cached_url) ||
        Boolean(this.to)
      );
    },
    element() {
      if (this.isLink) {
        // If the URL property in the Storyblok editor is set to an internal link (i.e. a story), then the link will be a nuxt-link
        if (this.blok.url?.linktype === "story" || this.blok.link?.linktype === "story" || this.to) {
          return "nuxt-link";
        }

        return "a";
      }

      return "button";
    },
    textContent() {
      return this.blok.text || this.text;
    },
    urlContent() {
      const url = this.url || this.to || this.blok.url?.cached_url || this.blok.link?.cached_url;

      if (this.to.includes('/') || this.url.includes('/')) {
        return url;
       }

      return this.element === "nuxt-link" ? `/${url}` : url;
    },
    variantContent() {
      return this.blok.variant || this.variant;
    },
  },
};
