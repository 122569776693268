
  import { mapState, mapMutations } from 'vuex'

  import mainMenu from '~/assets/menus/main.json'
  import mobileMenu from '~/assets/menus/mobile.json'
  import mobileBottomMenu from '~/assets/menus/mobile-bottom.json'

  export default {
    computed: {
      mainMenu() {
        return mainMenu
      },
      mobileMenu() {
        return mobileMenu
      },
      mobileBottomMenu() {
        return mobileBottomMenu
      },
      ...mapState(['menu','channel','location']),
      ...mapState({
        showMenu: state => state.app.showMenu,
        hidingMenu: state => state.app.hidingMenu,
      }),
    },
    mounted() {
      document.addEventListener("click", this.handleClickOutside);
    },
    beforeDestroy() {
      document.removeEventListener("click", this.handleClickOutside);
    },
    methods: {
      ...mapMutations({
        setShowMenu: 'app/setShowMenu',
        setHidingMenu: 'app/setHidingMenu',
        setCateringDialogue: "app/setCateringDialogue",
      }),
      isActive(page) {
        if (page == 'catering') {
          if (this.$route.path.split('/')[1] !== 'locations') {
            return this.channel == 'catering'
          }
        }
        if (page == 'order') {
          if (this.channel !== 'catering') {
            return this.$route.path.split('/')[1] == 'menu'
          } else {
            return false
          }
        }
        return page == this.$route.path.split('/')[1]
      },
      startMealtime() {
        if (this.channel == 'catering') {
          this.setCateringDialogue(true)
        } else {
          if (this.location) {
            this.$router.push('/order')
          } else {
            this.$router.push('/menu')
          }
        }
      },
      startCatering() {
        if (this.channel == 'catering') {
          this.$router.push('/order')
        } else {
          this.$router.push('/catering')
        }
      },
      toggleMenu() {
        if (this.showMenu) {
          this.setHidingMenu(true)
          setTimeout(function() {
            this.setShowMenu(false)
            this.setHidingMenu(false)
          }.bind(this), 500)
        } else {
          this.setShowMenu(true)
        }
      },
      handleClickOutside(event) {
        const toggleMenuButton = document.querySelector('#toggle-menu');
        const toggleMenuButtonIcon = document.querySelector('#toggle-menu svg');
        const menuElement = this.$refs.menu;

        const shouldToggleMenu = !menuElement.contains(event.target) && !toggleMenuButton.contains(event.target) && !toggleMenuButtonIcon.contains(event.target);

        if (shouldToggleMenu) {
          this.toggleMenu()
        }
      },
    }
  }
