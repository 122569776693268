
import { mapState, mapMutations, mapActions } from "vuex";
import Radar from "~/node_modules/radar-sdk-js";
import "radar-sdk-js/dist/radar.css";

export default {
  props: {
    showDeliveryLabel: {
      type: Boolean,
      default: true,
    },
    ctaText: {
      type: String,
      default: "Deliver to this address",
    },
    shouldRedirectAfterSubmit: {
      type: Boolean,
      default: true,
    },
    shouldGetAddressesAfterSubmit: {
      type: Boolean,
      default: false,
    },
    containerElementId: {
      type: String,
      default: "autocomplete-delivery"
    }
  },
  data() {
    return {
      query: "",
      city: "",
      state: "",
      lat: "",
      lng: "",
      zipCodeField: {
        name: "zipCode",
        label: "Zip code",
        type: "text",
        value: "",
        placeholder: "Enter the Zip code",
        mask: "#####",
        valid: /^[0-9]{5}/,
        errors: {
          invalid: "Please enter a valid zip code.",
        },
      },
      complementField: {
        name: "complement",
        label: "Suite/Apt/Door",
        type: "text",
        value: "",
        placeholder: "Enter the Suite/Apt/Door",
        valid: /^[A-Za-z0-9]{20}/,
        errors: {
          invalid: "Please enter a valid suite/apt/door.",
        },
      },
    };
  },
  computed: {
    ...mapState(["loading", "cart", "location"]),
    ...mapState({
      deliveryAddress: (state) => state.locations.deliveryAddress,
    }),
    orderDeliveryDisabled() {
      if (this.loading) return true;

      if (!this.query || !this.city || !this.state || !this.zipCodeField.value)
        return true;

      return false;
    },
  },
  mounted() {
    const radarKey = process.env.RADAR_KEY;
    if (!radarKey) {
      this.$utils.log("LocationDeliveryForm - RADAR_KEY is missing");
      console.log("LocationDeliveryForm - RADAR_KEY is missing");
      return;
    }

    // Initialize Radar SDK
    Radar.initialize(radarKey);

    // Create autocomplete widgets
    Radar.ui.autocomplete({
      container: this.containerElementId,
      countryCode: 'US',
      country: 'US',
      expandUnits: true,
      responsive: true,
      placeholder: "Enter the Address",
      onSelection: (result) => {
        document.querySelector(`#${containerElementId} input"`).value =
          result.addressLabel || result.placeLabel || "";
        this.query = result.addressLabel || result.placeLabel || "";
        this.zipCodeField.value = result.postalCode;
        this.city = result.city;
        this.state = result.stateCode;
        this.lat = result.latitude;
        this.lng = result.longitude;
      },
    });

    // Add autofill functionality
    this.$nextTick(() => {
      document.addEventListener("keyup", (e) => {
        if (
          e.target.parentElement.parentElement?.id == this.containerElementId
        ) {
          this.query = e.target.value;
        }
      });
    });

    if (this.cart?.address?.address) {
      this.updateForm();
    }
  },
  methods: {
    ...mapActions([ "getCart", "getDeliveryLocations", "sendRequest" ]),
    ...mapActions({
      applyCartHandoff: "checkout/applyCartHandoff",
    }),
    ...mapActions({
      getAddresses: "members/getAddresses",
    }),
    ...mapMutations({
      setDeliveryAddress: "locations/setDeliveryAddress",
      setShowLocationDeliveryForm: "app/setShowLocationDeliveryForm",
    }),
    ...mapMutations([ "setErrors", "setLocation", "setLoading" ]),
    async handleSubmit() {
      let address = document.querySelector('.radar-autocomplete-input')?.value

      if (!address) {
        this.setErrors(['Please enter a valid address.'])
        return false
      }

      // Get first result from radar
      const axios = require('axios')

      const baseUrl = 'https://api.radar.io/v1/search/autocomplete?query='
      const zipcode = this.zipCodeField.value
      const city = this.city || ''
      const state = this.state || ''
      const countryCode = "&countryCode=US"

      const url = `${baseUrl}${address}+${zipcode}+${city}+${state}+${countryCode}`

      const config = {
        method: 'GET',
        url,
        headers: {
          'Authorization': process.env.RADAR_KEY,
        },
      }

      const result = await axios(config)
        .then(res => {
          return {
            statusCode: res.status,
            body: JSON.stringify(res.data)
          }
        })
        .catch(err => {
          return {
            statusCode: err.response.status,
            body: JSON.stringify(err.response.data)
          }
        })
      let statusCode = JSON.parse(result.statusCode)
      let body = JSON.parse(result.body)
      if (statusCode == 200) {
        address = body.addresses[0]
      } else {
        this.setErrors(['There are no results for the address entered.'])
        return false
      }

      let location = null

      if (this.cart?.time?.mode == 'advance') {
        location = await this.getDeliveryLocations({
          address: address.addressLabel,
          city: address.city,
          zip: address.postalCode,
          state: address.stateCode,
          lat: address.latitude,
          lng: address.longitude,
          time: this.cart.time.wanted
        })
      } else { // asap
        location = await this.getDeliveryLocations({
          address: address.addressLabel,
          city: address.city,
          zip: address.postalCode,
          state: address.stateCode,
          lat: address.latitude,
          lng: address.longitude,
        })
      }

      if (location === null) return;

      this.setDeliveryAddress({
        address: address.addressLabel,
        city: address.city,
        state: address.stateCode,
        zip: address.postalCode,
        building: this.complementField.value,
        lat: address.latitude,
        lng: address.longitude,
        // instructions: this.instructionsField.value,
        // phone: this.phoneField.value,
      });

      await this.applyCartHandoff({
        address: address.addressLabel,
        city: address.city,
        state: address.stateCode,
        zip: address.postalCode,
        building: this.complementField.value,
        // phone: this.phoneField.value,
        // instructions: this.instructionsField.value,
        default: false,
        handoffMode: "delivery",
        save: true,
      });

      this.setShowLocationDeliveryForm(false)
      await this.refreshLocationData(location.menu_id)

      this.$emit("hide-delivery-form");

      await this.getCart();

      if (this.shouldGetAddressesAfterSubmit) {
        await this.getAddresses();
      }

      if (this.shouldRedirectAfterSubmit) {
        this.$router.push("/order");
      }

      const shouldEmitShippingInfoEvent = 
        this.cart &&
        this.cart.handoff === "delivery" &&
        this.$route.name === "order-checkout" &&
        this.location

      if (shouldEmitShippingInfoEvent) {
        this.$gtm.push({ ecommerce: null })
        this.$gtm.push({
          event: "add_shipping_info",
          ecommerce: {
            currency: 'USD',
            value: this.cart.totals.subtotal,
            location_name: this.location.name,
            location_id: this.location.id,
            order_type: this.cart.handoff,
            items: this.cart.products.map(product => {
              return {
                item_id: product.id,
                item_name: product.name,
                quantity: product.quantity
              }
            })
          }
        })
      }
    },
    async refreshLocationData(menuId) {
      const locConfig = {
        method: 'get',
        url: `locations/${menuId}`,
        params: {
          byMenuId: true
        }
      }
      let response = await this.sendRequest(locConfig)
      if (response.status === 'ok') {
        await this.setLocation(response.data)
      }
    },
    updateForm() {
      console.log('updating...')
      document.querySelector(`#${this.containerElementId} input`).value =
        this.cart?.address?.address ?? "";
      this.query = this.cart?.address?.address ?? "";
      this.city = this.cart?.address?.city ?? "";
      this.state = this.cart?.address?.state ?? "";
      this.zipCodeField.value = this.cart?.address?.zip ?? "";
      this.complementField.value = this.cart?.address?.building ?? "";
    },
  },
};
